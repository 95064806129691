const Engineux = require('engineux').default;

module.exports = function (style) {
  const $header = document.getElementById('header');
  return new Engineux({
    'start': {
      data: {
        [`#${$header.id}`]: {
          transform: 'translate(0, 0%)',
          transition: 'transform .5s ease-in-out, ',
          bottom: 0
        },
      },
      on: {
        'activate': {
          next: 'active'
        }
      }
    },
    'active': {
      data: {
        styles: {
          [`#${$header.id}`]: {
            transform: 'translate(0, 100%)',
            transition: 'transform .5s ease-in-out, bottom .5s ease-in-out',
            bottom: '100%'
          }
        }
      },
      on: {
        'deactivate': {
          next: 'start'
        }
      }
    }
  }, 'start', ({data}) => {
    style.update(data.styles)
  });
}

