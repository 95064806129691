const Engineux = require('engineux').default;

module.exports = function (style) {
  const $splash = document.getElementById('splash');
  return new Engineux({
    'start': {
      data: {
        styles: {
          '#loader': {
            display: null,
          },
          '.loadingBar-bar': {
            width: '10%'
          }
        }
      },
      on: {
        'half_done': {
          next: 'half_done'
        }
      }
    },
    'half_done': {
      data: {
        styles: {
          '.loadingBar-bar': {
            width: '50%'
          }
        }
      },
      on: {
        'almost_done': {
          next: 'almost_done'
        }
      }
    },
    'almost_done': {
      data: {
        styles: {
          '.loadingBar-bar': {
            width: '95%'
          }
        }
      },
      on: {
        'completed': {
          next: 'completed'
        }
      }
    },
    'completed': {
      data: {
        styles: {
          '#loader': {
            display: 'none',
          },
        }
      },
      on: {
        'loading': {
          next: 'start'
        }
      }
    }
  }, 'start', ({data}) => {
    style.update(data.styles)
  });
}
