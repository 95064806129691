(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./utils"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./utils"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.utils);
    global.index = mod.exports;
  }
})(this, function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _utils = _interopRequireDefault(_utils);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

  class Store {
    constructor(state) {
      const store = {};
      store.state = _utils.default.run('clone', state || {});
      store.prev = _utils.default.run('clone', state || {});
      store.onChange = null;
      store.changed = [];
      return this.methods(store);
    }

    methods(store, batching = false) {
      return {
        get: ref => this.get.call(this, store, ref),
        set: (ref, value) => this.set.call(this, store, ref, value, batching),
        update: (ref, value) => this.update.call(this, store, ref, value, batching),
        delete: ref => this.delete.call(this, store, ref),
        findWhere: (ref, value) => this.findWhere.call(this, store, ref, value),
        ref: ref => this.ref.call(this, store, ref),
        onChange: cb => {
          store.onChange = cb.bind(store);
        },
        batch: cb => this.batch.call(this, store, cb)
      };
    }

    batch(store, cb) {
      const methods = this.methods.call(this, store, true);

      methods.done = () => {
        this.done.call(this, store, store.changed.slice());
        store.changed = [];
      };

      return cb(methods);
    }

    ref({
      state,
      prev,
      dispatch,
      onChange
    }, ref) {
      let child = _utils.default.run('getPropValue', {
        ref,
        state
      });

      if (!child) {
        this.set({
          state,
          prev,
          dispatch,
          onChange
        }, ref, {});
      }

      const store = {};
      store.parent = ref;
      store.state = child || _utils.default.run('getPropValue', {
        ref,
        state
      });
      store.prev = _utils.default.run('getPropValue', {
        ref,
        state: prev
      });
      store.onChange = null;
      return this.methods(store);
    } // Getters and Setters


    get({
      state
    }, ref = null) {
      return ref ? _utils.default.run('clone', _utils.default.run('getPropValue', {
        ref,
        state
      })) : _utils.default.run('clone', state);
    }

    set(store, r, v, batching) {
      let {
        state,
        prev,
        onChange,
        changed
      } = store;
      v = _utils.default.run('clone', v);

      if (!r || r === '*') {
        store.prev = _utils.default.run('clone', state);
        store.state = v;
        return {
          prev: store.prev,
          state: store.state
        };
      }

      let res = r.split('.').reduce(({
        state,
        prev
      }, x, i, a) => {
        if (i === a.length - 1) {
          prev[x] = _utils.default.run('clone', state[x]);
          return state[x] = v;
        } else {
          return {
            prev: x in prev && prev[x] !== null && typeof state[x] === 'object' ? prev[x] : prev[x] = {},
            state: x in state && state[x] !== null && typeof state[x] === 'object' ? state[x] : state[x] = {}
          };
        }
      }, {
        state,
        prev
      });

      if (batching) {
        changed.push(r);
      } else {
        this.done({
          state,
          prev,
          onChange
        }, r);
      }

      return res;
    }

    update({
      state,
      prev,
      onChange,
      changed
    }, r, v, batching) {
      v = _utils.default.run('clone', v);
      let res = r.split('.').reduce(({
        state,
        prev
      }, x, i, a) => {
        if (i === a.length - 1) {
          prev[x] = _utils.default.run('clone', state[x]);
          return typeof v === 'object' ? state[x] = typeof state[x] == 'object' ? { ...state[x],
            ...v
          } : v : state[x] = v;
        } else {
          return {
            prev: x in prev && prev[x] && prev[x] !== null && typeof prev[x] === 'object' ? prev[x] : prev[x] = {},
            state: x in state && state[x] && state[x] !== null && typeof state[x] === 'object' ? state[x] : state[x] = {}
          };
        }
      }, {
        state,
        prev
      });

      if (batching) {
        changed.push(r);
      } else {
        this.done({
          state,
          prev,
          onChange
        }, r);
      }

      return res;
    }

    delete({
      state,
      prev,
      onChange,
      changed
    }, ref = null, batching) {
      let res = ref.split('.').reduce(({
        state,
        prev
      }, x, i, a) => {
        if (state != undefined && i === a.length - 1) {
          let val = state[x];
          prev[x] = _utils.default.run('clone', val);

          if (!Array.isArray(state)) {
            delete state[x];
            return val;
          } else {
            return state.splice(x, 1).shift();
          }
        }

        return state == undefined ? {
          state,
          prev
        } : {
          state: state[x],
          prev: prev[x]
        };
      }, {
        state,
        prev
      });

      if (batching) {
        changed.push(r);
      } else {
        this.done({
          state,
          prev,
          onChange
        }, ref);
      }

      return res;
    }

    findWhere({
      state
    }, k, v, c = {
      ref: [],
      res: []
    }) {
      return Object.keys(state).reduce((c, x, i, a) => {
        if (k === x && state[x] === v) {
          let ref = [...c.ref.slice(), ...[x]].join('.');
          c.res.push(ref);
        }

        if (typeof state[x] === 'object' && state[x] !== null) {
          let res = this.findWhere({
            state: state[x]
          }, k, v, {
            ref: [...c.ref.slice(), ...[x]],
            res: []
          });
          c.res = 'res' in res ? [...c.res, ...res.res] : [...c.res, ...res];
        }

        return i < a.length - 1 ? c : c.res;
      }, c);
    }

    done({
      state,
      prev,
      onChange
    }, r) {
      if (onChange) {
        onChange(_utils.default.run('clone', state || {}), _utils.default.run('clone', prev), r);
      }
    }

  }

  _exports.default = Store;
});