const Engineux = require('engineux').default;

module.exports = function (style) {
  return Array.from(document.querySelectorAll('.js-page')).reduce((pages, $page) => {
    const id  = $page.id;
    pages[id] = new Engineux({
      'start': {
        data: {
          styles: {
            [`#${id}`]: {
              top: 0,
              left: 0,
              transform: 'translate(2rem, 100vh)'
            },
          }
        },
        on: {
          'activate': {
            next: 'active'
          }
        }
      },
      'active': {
        enter: () => {
          $page.firstElementChild.scrollTop = 0
        },
        data: {
          styles: {
            [`#${id}`]: {
              top: 0,
              left: 0,
              transition: 'transform .5s ease-in-out',
              transform: 'translate(2rem, 6.6vw)'
            },
          }
        },
        on: {
          'back': {
            next: 'start'
          },
          'pass': {
            next: 'past'
          },
        }
      },
      'past': {
        data: {
          styles: {
            [`#${id}`]: {
              top: 0,
              left: 0,
              transition: 'transform .5s ease-in-out',
              transform: 'translate(2rem, -100vh)'
            },
          }
        },
        on: {
          'reset': {
            next: 'start'
          },
        }
      }
    }, 'start', ({data, state, emit}) => {
      style.update(data.styles);
    });
    return pages
  }, {})
}