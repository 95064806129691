const Styleux = require('styleux');
const Engineux = require('engineux').default;
const getSplash = require('./splash');
const getHeader  = require('./header');
const getPages  = require('./pages');
const getLinks = require('./links');
const getGalleries = require('./galleries');
const getLoader = require('./loader');

;((fn) => {
  if (document.readyState != 'loading'){
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
})(() => {
  const body = document.querySelector('.js-body');
  const style  = new Styleux();
  const splash = getSplash(style);
  const header = getHeader(style);
  const pages = getPages(style);
  const links = getLinks(style);
  const loader = getLoader(style);
  const galleries = getGalleries()

  function showPage(page) {
    Object.keys(pages).forEach((pageId) => {
      if (pageId === page && pages[pageId].getState() !== 'active') {
        pages[pageId].emit('activate')
      } else if (pages[pageId].getState() === 'active') {
        pages[pageId].emit('pass');
        setTimeout(() => {
          pages[pageId].emit('reset');
        }, 500)
      }
    });
    
    if (splash.getState() !== 'active') {
      splash.emit('activate');
    }
    
    if (header.getState() !== 'active') {
      header.emit('activate');
    }
  }

  const mainEvents = {
    'apps': {
      next: 'apps'
    },
    'open_source': {
      next: 'open_source'
    },
    'lunch_break': {
      next: 'lunch_break'
    },
    'offline': {
      next: 'offline'
    }
  }

  const main = new Engineux({
    loading: {
      enter: ({emit}) => {
        loader.start();
                
        const imgs = Array.from(document.images);
        
        imgs.map((img) => {
          if (img.complete) {
            return Promise.resolve();
          } else {
            return new Promise((resolve) => {
              img.addEventListener( 'load', resolve, false );
            })
          }
        });

        loader.emit('half_done');

        setTimeout(() => {
          Promise.all(imgs).then(() => {
            loader.emit('almost_done');
            setTimeout(() => {
              loader.emit('completed');
              emit('success');
            }, 500)
          });
        }, 1200)
      },
      on: {
        success: {
          next: 'start'
        }
      }
    },
    start: {
      enter: () => {
        splash.start();
        header.start();
        Object.keys(pages).forEach(key => pages[key].start());
        Object.keys(links).forEach(key => links[key].start());
        Object.keys(galleries).forEach(key => galleries[key].start());
        const images = document.querySelectorAll('.js--gallery-image');
      },
      on: {
        ...mainEvents
      },
      data: {
        styles: {
          '#main': {
            opacity: 1
          },
          '#splash': {
            opacity: 1
          },
          '#header': {
            opacity: 1
          }
        }
      }
    },
    apps: {
      enter: () => {
        showPage('page_apps')
      },
      on: {
        ...mainEvents
      }
    },
    open_source: {
      enter: () => {
        showPage('page_open_source')
      },
      on: {
        ...mainEvents
      }
    },
    lunch_break: {
      enter: () => {
        showPage('page_lunch_break')
      },
      on: {
        ...mainEvents
      }
    },
    offline: {
      enter: () => {
        showPage('page_offline')
      },
      on: {
        ...mainEvents
      }
    }
  }, 'loading', ({data}) => {
    // style.update(data.styles)
  }).start();

  for (const event in mainEvents) {
    body.addEventListener(event, () => main.emit(event))
  }
});

