const Engineux = require('engineux').default;
const Vue = require('vue/dist/vue');
const lightbox = new Vue({
  data: {
    show: false,
    thumbnails: [],
    largeImageUrl: '',
    cb: null,
    imageIndex: null
  },
  methods: {
    close () {
      if (typeof this.cb === 'function') {
        this.cb();
      }
    },
    setLargeImage (largeImage) {
      this.largeImageUrl = largeImage
    },
    next () {
      const currentIndex = this.thumbnails.indexOf(this.largeImageUrl);
      if (currentIndex < this.thumbnails.length - 1) {
        this.setLargeImage(this.thumbnails[currentIndex + 1])
      } else {
        this.setLargeImage(this.thumbnails[0])
      }
    },
    prev () {
      const currentIndex = this.thumbnails.indexOf(this.largeImageUrl);
      if (currentIndex > 0) {
        this.setLargeImage(this.thumbnails[currentIndex - 1])
      } else {
        this.setLargeImage(this.thumbnails[this.thumbnails.length - 1])
      }
    }
  }
}).$mount('#gallery')

module.exports = function (style) {
  return Array.from(document.querySelectorAll('.js--gallery')).map(($gallery) => {
    const imageUrls = Array.from($gallery.children).map(($node) => $node.firstElementChild.getAttribute('data-image-url'));
    return new Engineux({
      hidden: {
        enter: () => {
          lightbox.show = false;
          lightbox.thumbnails = [];
          lightbox.largeImageUrl = null;
          lightbox.cb = null
        },
        on: {
          click: {
            next: 'showing'
          }
        },
      },
      showing: {
        enter: ({emit}, _, __, largeImageUrl) => {
          lightbox.show = true;
          lightbox.thumbnails = imageUrls;
          lightbox.largeImageUrl = largeImageUrl || imageUrls[0];
          lightbox.cb = () => emit('close')
        },
        on: {
          close: {
            next: 'hidden'
          }
        }
      }
    }, 'hidden', ({on, emit}) => {
      for (const event in on) {
        $gallery[`on${event}`] = (e) => {
          const largeImageUrl = e.target.getAttribute('data-image-url');
          emit(event, largeImageUrl);
        }
      }
    })
  })
}