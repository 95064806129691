const Engineux = require('engineux').default;

module.exports = function (style) {
  const $splash = document.getElementById('splash');
  return new Engineux({
    'start': {
      data: {
        styles: {
          [`#${$splash.id}`]: {
            transform: 'translate(0, 0%)',
            transition: 'transform .5s ease-in-out'
          },
        }
      },
      on: {
        'activate': {
          next: 'active'
        }
      }
    },
    'active': {
      data: {
        styles: {
          [`#${$splash.id}`]: {
            transform: 'translate(0, -100%)',
            transition: 'transform .5s ease-in-out'
          }
        }
      },
      on: {
        'deactivate': {
          next: 'start'
        }
      }
    }
  }, 'start', ({data}) => {
    style.update(data.styles)
  });
}
