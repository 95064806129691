(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define([], factory);
  } else if (typeof exports !== "undefined") {
    factory();
  } else {
    var mod = {
      exports: {}
    };
    factory();
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function () {
  "use strict";

  module.exports = class {
    constructor() {
      this.styles = {};
      this.window = window;
      this.frame = null;
      this.queue = [];

      this._methods = ctx => ({
        update: (...args) => this.update.call(ctx, ctx, ...args)
      });

      return { ...this._methods(this),
        setWindow: window => this.window = window
      };
    }

    update({
      styles,
      addToQueue,
      window
    }, updates, done) {
      this.done = done;

      for (let uid in updates) {
        const style = updates[uid];
        const styleNode = uid in styles ? styles[uid] : window.document.createElement('style');
        const keys = style !== null ? Object.keys(style) : null;
        const innerText = keys && keys.length ? keys.reduce((accumulator, key, i, a) => {
          accumulator += `${key}:${style[key]};`;
          return i < a.length - 1 ? accumulator : accumulator += '}';
        }, `${uid} {`) : '';
        styles[uid] = styleNode;
        addToQueue(this, () => {
          if (styleNode.innerText !== innerText) {
            styleNode.innerText = innerText;
          }

          if (!styleNode.parentNode) {
            window.document.head.appendChild(styleNode);
          }
        });
      }

      return this._methods(this);
    }

    addToQueue(ctx, fn) {
      let {
        queue,
        window,
        done
      } = ctx;

      if (fn in queue) {
        const index = queue.indexOf(fn);
        queue[index] = fn;
      } else {
        queue.push(fn);
      }

      if (ctx.frame) {
        window.cancelAnimationFrame(ctx.frame);
      }

      ctx.frame = window.requestAnimationFrame(() => {
        while (queue.length) {
          queue.shift()();
        }

        if (typeof done === 'function') {
          done();
        }
      });
      return ctx._methods(ctx);
    }

  };
});