(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "runeux", "clone-deep", "type-detect"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("runeux"), require("clone-deep"), require("type-detect"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.runeux, global.cloneDeep, global.typeDetect);
    global.utils = mod.exports;
  }
})(this, function (_exports, _runeux, _cloneDeep, _typeDetect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _runeux = _interopRequireDefault(_runeux);
  _cloneDeep = _interopRequireDefault(_cloneDeep);
  _typeDetect = _interopRequireDefault(_typeDetect);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

  const utils = new _runeux.default({
    type: ({}, o) => (0, _typeDetect.default)(o).toLocaleLowerCase(),
    getPropValue: ({}, {
      ref,
      state
    }) => ref.split('.').reduce((o, x) => o == undefined ? o : o[x], state),
    clone: ({}, obj) => (0, _cloneDeep.default)(obj),

    update({
      run
    }) {
      let key = keys.shift();
      accumulator[key] = typeof value[key] === 'object' ? update(ref + key, value[key], Object.keys(value[key]), accumulator[key] || {}) : value[key];
      return keys.length > 0 ? run('update', ref, value, keys, accumulator) : accumulator;
    }

  });
  var _default = utils;
  _exports.default = _default;
});