const Engineux = require('engineux').default;

module.exports = function (style, pages, splash, header) {
  const state = window.localStorage.getItem('mill_state');

  return Array.from(document.querySelectorAll('.js--nav-link')).reduce((links, $link) => {
    const linkPageId = $link.getAttribute('data-page');
    const pageEvent = new Event(linkPageId, {bubbles: true});
    links[$link.id] = new Engineux({
      'start': {
        data: {
          styles: {
            [`#${$link.id}`]: {},
            [`#${$link.id}:after`]: {}
          }
        },
        on: {
          'click': {
            next: 'active',
            prevent: true
          }
        }
      },
      'active': {
        data: {
          styles: {
            [`#${$link.id}`]: {
              color: '#555',
              transform: 'translate(0, -.1em)',
            },
            [`#${$link.id}:after`]: {
              "transition-delay": 0,
              "transform": "translate(0, .1em)",
              "opacity": 1,
            }
          }
        },
        on: {
          'back': {
            next: 'start',
            prevent: true
          }
        }
      }
    }, 'start', ({data, on, emit}) => {
      for (const event in on) {
        const eventRef = `on${event}`;
        if (eventRef in $link) {
          $link[eventRef] = (e) => {
          
            if (on[event].prevent) {
              e.preventDefault();
            }
  
            Object.keys(links).forEach((linkId) => {
              if (links[linkId].getState() === 'active' && linkId != $link.id) {
                links[linkId].back();
              }           
            });
  
            emit(event);
            $link.dispatchEvent(pageEvent);
          }
        }
      };

      style.update(data.styles);
    });
    return links;
  }, {});
}
